import { inject, Injectable, isDevMode, NgZone } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SystemStateService } from '@state-management/system-state';
import {
  catchError,
  concat,
  filter,
  forkJoin,
  from,
  interval,
  mergeMap,
  of,
  Subscription,
  tap,
} from 'rxjs';
import { DialogService } from './dialog.service';
import { OfflineIndexedDbService } from './indexed-db/offline/offline-indexed-db.service';

@Injectable({
  providedIn: 'root',
})
export class CheckForUpdateService {
  private readonly translateService = inject(TranslateService);
  private readonly dialogService = inject(DialogService);
  private readonly systemStateService = inject(SystemStateService);
  private readonly isOffline = this.systemStateService.getValue('isOffline');
  private readonly ngZone = inject(NgZone);
  private readonly router = inject(Router);
  private readonly offlineIndexedDbService = inject(OfflineIndexedDbService);

  private intervalSubscription: Subscription | undefined;
  private everyMinutes$ = interval(
    environment.minutes_interval_check_new_app_version * 60 * 1000,
  );
  private everyMinutesOnceAppIsStable$ = concat(
    toObservable(this.isOffline),
    this.everyMinutes$,
  );

  private readonly swUpdate = inject(SwUpdate);

  start(): void {
    this.intervalSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) return;
    if (isDevMode()) {
      console.info(
        `${this.getFormattedDateTime()} - ${this.translateService.instant('SERVICE_WORKER_DISABLED')}`,
      );

      return;
    }

    console.info(
      `${this.getFormattedDateTime()} - ${this.translateService.instant('SERVICE_WORKER_ENABLED')}`,
    );

    this.ngZone.runOutsideAngular(() => {
      this.intervalSubscription = this.everyMinutesOnceAppIsStable$
        .pipe(
          filter(() => !this.isOffline()),
          mergeMap(() => {
            const existsDataToSync$ =
              this.offlineIndexedDbService.existsDataToSync();
            const checkUpdate$ = from(this.swUpdate.checkForUpdate()).pipe(
              catchError((err) => {
                console.warn(
                  `${this.getFormattedDateTime()} - ${this.translateService.instant('FAIL_CHECKING_NEW_APP_VERSION')}`,
                  err,
                );
                return of(false);
              }),
            );

            return forkJoin([existsDataToSync$, checkUpdate$]).pipe(
              tap(([existsDataToSync, updateFound]) => {
                this.systemStateService.updateValue(
                  'isUpdateAvailable',
                  (value) => value || updateFound,
                );
                if (updateFound) {
                  console.info(
                    `${this.getFormattedDateTime()} - ${this.translateService.instant('NEW_APP_VERSION_READY')}`,
                  );
                  this.applyUpdate(existsDataToSync);
                } else {
                  console.info(
                    `${this.getFormattedDateTime()} - ${this.translateService.instant('YOU_HAVE_LAST_APP_VERSION')}`,
                  );
                }
              }),
            );
          }),
        )
        .subscribe();
    });
  }

  getFormattedDateTime(): string {
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(new Date());
  }

  applyUpdate(existsDataToSync = false): void {
    let message = '',
      cancelLabelButton = 'UPDATE_LATER',
      cancelAction = (): void => {
        return;
      };

    if (existsDataToSync) {
      message = 'UPDATE_DATA_WITHOUT_SYNC';
      cancelLabelButton = 'GO_TO_SYNC';
      cancelAction = (): void => {
        this.router.navigate(['/home/settings/offline-mode']);
      };
    }

    this.dialogService.open({
      title: 'NEW_APP_VERSION_READY',
      message,
      confirmAction: this.reload.bind(this),
      confirmButtonLabel: 'UPDATE',
      cancelButtonLabel: cancelLabelButton,
      cancelAction,
    });
  }

  private reload(): void {
    this.swUpdate
      .activateUpdate()
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.error('Failed to apply updates: ', err));
  }
}
