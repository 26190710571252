<router-outlet />

@defer {
  <nax-environment-indicator *naxFeatureFlag="'ENVIRONMENT_INDICATOR'" />
  <nax-snackbar-container />
  <!-- ↓↓↓ Provisional solution, must be replaced when is designed and defined  -->
  @if (isOffline()) {
    <nax-icon
      class="nax-root__offline-indicator | portrait-only bg--surface elevation-level1"
      icon="wifi_off" />
  }
  <!-- ↑↑↑ Provisional solution END  -->

  <nax-dialog
    #defaultDialog
    [cancelAction]="dialogInfo().cancelAction">
    <ng-container dialog-title>
      @if (dialogInfo().title; as title) {
        <h2 class="break-word">
          {{ title | naxTranslate }}
        </h2>
      }
    </ng-container>
    @if (dialogInfo().message) {
      @for (message of dialogInfo().message; track message) {
        <p class="body--medium">{{ message | naxTranslate }}</p>
      }
    }
    <ng-container dialog-footer>
      @if (!(dialogInfo().showCancelButton === false)) {
        <nax-cta
          appearance="text"
          [label]="dialogInfo().cancelButtonLabel ?? 'CANCEL'"
          (click)="defaultDialog.cancelDialog()" />
      }
      <nax-cta
        [label]="dialogInfo().confirmButtonLabel ?? 'CONFIRM'"
        (click)="dialogInfo().confirmAction?.(); defaultDialog.closeDialog()" />
    </ng-container>
  </nax-dialog>
}
