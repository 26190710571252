import { DOCUMENT } from '@angular/common';
import { effect, inject, Injectable } from '@angular/core';
import { ColorSchemeEnum } from '@enums/color-scheme.enum';
import { TranslateService } from '@ngx-translate/core';
import { MeConfigStateService } from '@state-management/me-config-state';
import { take, tap } from 'rxjs';
import { PrimeTranslateService } from './prime-translate.service';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private _document = inject(DOCUMENT);
  private readonly meConfigStateService = inject(MeConfigStateService);
  private readonly translateService = inject(TranslateService);
  private readonly primeTranslateService = inject(PrimeTranslateService);

  constructor() {
    this.setThemePreferencesEffect();
    this.setLanguagePreferencesEffect();
  }

  private setThemePreferencesEffect(): void {
    effect(() => {
      const isDarkTheme =
        this.meConfigStateService.getValue('theme')() === ColorSchemeEnum.Dark;

      return isDarkTheme
        ? this._document.documentElement.setAttribute('data-theme', 'dark')
        : this._document.documentElement.removeAttribute('data-theme');
    });
  }

  private setLanguagePreferencesEffect(): void {
    effect(() => {
      const language = this.meConfigStateService.getValue('language')();

      this._document.documentElement.lang = language;
      this.translateService
        .use(language)
        .pipe(
          take(1),
          tap(() => this.primeTranslateService.setTranslation()),
        )
        .subscribe();
    });
  }
}
