import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AuthStateService } from '@state-management/auth-state';
import { SystemStateService } from '@state-management/system-state';
import { Observable, catchError, throwError } from 'rxjs';
import { handleErrorsFactory } from './utils/handle-errors.utils';

export const handleErrorsInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const isOffline = inject(SystemStateService).getValue('isOffline')();
  const refreshToken = inject(AuthStateService).getValue('refresh')();

  return next(request).pipe(
    catchError((error) => {
      if (isOffline) return throwError(() => error);

      return (
        handleErrorsFactory(request, next, authService, error, refreshToken) ??
        throwError(() => error)
      );
    }),
  );
};
