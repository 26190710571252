import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import {
  Component,
  OnInit,
  computed,
  inject,
  untracked,
  viewChild,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CtaComponent } from '@components/cta/cta.component';
import { DialogComponent } from '@components/dialog/dialog.component';
import { EnvironmentIndicatorComponent } from '@components/environment-indicator/environment-indicator.component';
import { FeatureFlagDirective } from '@directives/flag-directives/feature-flag.directive';
import { TranslatePipe } from '@pipes/translate.pipe';
import { CheckForUpdateService } from '@services/check-for-update.service';
import { DialogInfo, DialogService } from '@services/dialog.service';
import { SystemStateService } from '@state-management/system-state';
import { IconComponent } from './components/icon/icon.component';
import { SnackbarContainerComponent } from './components/toast/snackbar-container/snackbar-container.component';

@Component({
  selector: 'nax-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    SnackbarContainerComponent,
    IconComponent,
    EnvironmentIndicatorComponent,
    FeatureFlagDirective,
    DialogComponent,
    TranslatePipe,
    CtaComponent,
  ],
  providers: [DecimalPipe, DatePipe, TranslatePipe, PercentPipe],
})
export class AppComponent implements OnInit {
  private readonly checkForUpdateService = inject(CheckForUpdateService);
  private readonly systemStateService = inject(SystemStateService);
  private readonly dialogService = inject(DialogService);

  protected isOffline = this.systemStateService.getValue('isOffline');

  protected dialogComponent = viewChild.required(DialogComponent);
  protected dialogInfo = computed(() => this.populateDialogInfo());

  ngOnInit(): void {
    this.checkForUpdateService.start();
  }

  private populateDialogInfo(): DialogInfo {
    const dialogInfo = this.dialogService.dialogInfo();
    if (!dialogInfo) {
      untracked(() => this.dialogComponent().cancelDialog());
      return { message: null };
    }
    untracked(() => this.dialogComponent().openDialog());
    const message =
      typeof dialogInfo.message === 'string'
        ? [dialogInfo.message]
        : dialogInfo.message;
    return { ...(dialogInfo ?? {}), message };
  }
}
